import { createContext, useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import jwtDecode from "jwt-decode";
import Cookies from 'js-cookie';


const token= Cookies.get('token');


const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext(
  {
  ...initialState,
  method: 'JWT',
  logout: () => {},
  login: () => {},
   register: () => {}
});

export const AuthProvider = ({ children }) => {
 
  const logout = () => {
    dispatch({ type: 'LOGOUT' });
    Cookies.remove('token');

  };

const [state, dispatch] = useReducer(reducer, initialState);
  const login = async (email, password) => {
   
    const response = await axios.post('https://api.mhaj.com.br:3007/usuarios', { email, password }, 
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer aYBOvZ1rEHQPqLL6iG4R',
        'email': email,
        'password': password,
        // Add any other headers you need
      },
  });
  

  const obj = response.data[0];
  const userObj = { user: obj };
    const { user } = userObj;
     dispatch({ type: 'LOGIN', payload: { user }});
  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', { email, username, password });
    const { user } = response.data;
    
    dispatch({ type: 'REGISTER', payload: { user } });
  };

  

  useEffect(() => {
    (async () => {
      try {
        const email = localStorage.getItem('email');
        const { data } = await axios.get("https://api.mhaj.com.br:3007/usuarios", {
          params: {
            mail: email
          }
        });
                
        if (token === "Y3t?r!w/NAnANg6nFti!B5YtXBbpkfQr16Snxc2Uu/YU2Yhr6jcPNfZoV!CZ-scR") {
          const { user } = { user: data[0] }
          dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: user } });
        } else {
          dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
        }
        
      } catch (err) {
        console.error(err);
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
