import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

//Oficina
const AppGerencial = Loadable(lazy(() => import('./oficina/AppGerencial')));
const AppOrcamento = Loadable(lazy(() => import('./oficina/AppOrcamento')));
const AppVeiculos = Loadable(lazy(() => import('./oficina/AppVeiculos')));
const AppEmpresas = Loadable(lazy(() => import('./oficina/AppEmpresas')))
const AppCondutores = Loadable(lazy(() => import('./oficina/AppCondutores')));
const AppEntrega = Loadable(lazy(() => import('./oficina/AppEntrega')));
const AppEntrada = Loadable(lazy(() => import('./oficina/AppEntrada')));
const AppCadOrdServ = Loadable(lazy(() => import('./oficina/AppOrdServ')));
const VwrEmpresas = Loadable(lazy(() => import('./oficina/VwrEmpresas')));
const VwrCondutores = Loadable(lazy(() => import('./oficina/VwrCondutores')));
const VwrVeiculos = Loadable(lazy(() => import('./oficina/VwrVeiculos')));
const VwrAvarias = Loadable(lazy(() => import('./oficina/VwrAvarias')));


const VwrFotos = Loadable(lazy(() => import('./oficina/VwrFotos')));

const BuscaOsApp = Loadable(lazy(() => import('./oficina/BuscaOsApp')));

const QRCODE = Loadable(lazy(() => import('./oficina/qrcode')));
const Perfil = Loadable(lazy(() => import('./oficina/Perfil')));


//Oficina >> Entrada
const Entrada = Loadable(lazy(() => import('./oficina/Entrada')));
const Os = Loadable(lazy(() => import('./oficina/Entrada/VwrOs')));


//Default Layout
const AppTable = Loadable(lazy(() => import('./tables/AppTable')));
const AppForm = Loadable(lazy(() => import('./forms/AppForm')));
const AppButton = Loadable(lazy(() => import('./buttons/AppButton')));
const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const AppProgress = Loadable(lazy(() => import('./AppProgress')));
const AppMenu = Loadable(lazy(() => import('./menu/AppMenu')));
const AppCheckbox = Loadable(lazy(() => import('./checkbox/AppCheckbox')));
const AppSwitch = Loadable(lazy(() => import('./switch/AppSwitch')));
const AppRadio = Loadable(lazy(() => import('./radio/AppRadio')));
const AppSlider = Loadable(lazy(() => import('./slider/AppSlider')));
const AppDialog = Loadable(lazy(() => import('./dialog/AppDialog')));
const AppSnackbar = Loadable(lazy(() => import('./snackbar/AppSnackbar')));
const AppAutoComplete = Loadable(lazy(() => import('./auto-complete/AppAutoComplete')));
const AppExpansionPanel = Loadable(lazy(() => import('./expansion-panel/AppExpansionPanel')));

const materialRoutes = [
//Oficina
{ path: '/oficina/gerencial', element: <AppGerencial /> },
{ path: '/oficina/orcamento', element: <AppOrcamento /> },
{ path: '/oficina/veiculos', element: <AppVeiculos /> },
{ path: '/oficina/empresas', element: <AppEmpresas /> },
{ path: '/oficina/condutores', element: <AppCondutores /> },
{ path: '/oficina/entrada', element: <AppEntrada /> },
{ path: '/oficina/entrega', element: <AppEntrega /> },
{ path: '/oficina/cadordserv', element: <AppCadOrdServ /> },
{ path: '/oficina/vwrempresas', element: <VwrEmpresas /> },
{ path: '/oficina/vwrcondutores', element: <VwrCondutores /> },
{ path: '/oficina/vwrveiculos', element: <VwrVeiculos/> },
{ path: '/oficina/vwrAvarias', element: <VwrAvarias/> },
{ path: '/oficina/fotos', element: <VwrFotos/> },
{ path: '/oficina/buscaos', element: <BuscaOsApp/> },
{ path: '/oficina/getqrcode', element: <QRCODE/> },
{ path: '/oficina/perfil', element: <Perfil/> },


//Oficina >> Entrada
{ path: '/oficina/checkin', element: <Entrada/> },
{ path: '/oficina/os', element: <Os/> },

//Default Layout

  { path: '/material/table', element: <AppTable /> },
  { path: '/material/form', element: <AppForm /> },
  { path: '/material/buttons', element: <AppButton /> },
  { path: '/material/icons', element: <AppIcon /> },
  { path: '/material/progress', element: <AppProgress /> },
  { path: '/material/menu', element: <AppMenu /> },
  { path: '/material/checkbox', element: <AppCheckbox /> },
  { path: '/material/switch', element: <AppSwitch /> },
  { path: '/material/radio', element: <AppRadio /> },
  { path: '/material/slider', element: <AppSlider /> },
  { path: '/material/autocomplete', element: <AppAutoComplete /> },
  { path: '/material/expansion-panel', element: <AppExpansionPanel /> },
  { path: '/material/dialog', element: <AppDialog /> },
  { path: '/material/snackbar', element: <AppSnackbar /> }
];

export default materialRoutes;
